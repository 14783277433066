import AdjustIcon from '@material-ui/icons/Adjust';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SettingsIcon from '@material-ui/icons/Settings';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import BusinessIcon from '@material-ui/icons/Business';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StoreIcon from '@material-ui/icons/Store';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import EmailIcon from '@material-ui/icons/Email';
import { FormattedMessage } from 'react-intl';

import { useAuthContext } from '../../providers/AuthProvider';
import SidebarMenu from '../SidebarMenu';

const CUSTOMER = 'CUSTOMER';
const MERCHANT = 'MERCHANT';
const ADMIN = 'ADMIN';

const hasRights = (role, pageRestrictions, disableForReadonly, isReadonly) => {
  if (role === ADMIN) {
    if (disableForReadonly && isReadonly) {
      return false;
    }
    return true;
  }

  if (role === MERCHANT) {
    return pageRestrictions === MERCHANT || pageRestrictions === CUSTOMER;
  }

  return pageRestrictions === CUSTOMER;
};

const NavItems = (props) => {
  const { role, isReadonly } = useAuthContext();

  const navItems = [
    {
      label: 'Menu',
      icon: DashboardTwoToneIcon,
      content: [
        {
          label: <FormattedMessage id="Dashboard" />,
          to: '/dashboard',
          icon: DashboardIcon,
          restricted: ADMIN,
        },
        {
          label: (
            <FormattedMessage
              id={role === 'MERCHANT' ? 'Customers Merchant' : 'Customers'}
            />
          ),
          to: '/customers',
          icon: PersonIcon,
          restricted: MERCHANT,
        },
        {
          label: (
            <FormattedMessage
              id={
                role === 'MERCHANT'
                  ? 'Invoices Merchant'
                  : role === 'CUSTOMER'
                  ? 'Invoices Customer'
                  : 'Invoices'
              }
            />
          ),
          to: '/invoices',
          icon: CreditCardIcon,
          restricted: CUSTOMER,
        },
        {
          label: <FormattedMessage id="Orders" />,
          to: '/orders',
          icon: ReceiptIcon,
          restricted: MERCHANT,
        },
        {
          label: <FormattedMessage id="Sessions" />,
          to: '/sessions',
          icon: FormatListNumberedIcon,
          restricted: MERCHANT,
        },
        {
          label: <FormattedMessage id="Preregistrations" />,
          to: '/preregistrations',
          icon: HowToRegIcon,
          restricted: ADMIN,
        },
        {
          label: <FormattedMessage id="Settlements" />,
          to: '/settlements',
          icon: AdjustIcon,
          restricted: MERCHANT,
        },
        {
          label: <FormattedMessage id="Settlement reports" />,
          to: '/settlement-reports',
          icon: BusinessIcon,
          restricted: ADMIN,
        },
        {
          label: <FormattedMessage id="Merchants" />,
          to: '/merchants',
          icon: StorefrontIcon,
          restricted: ADMIN,
        },
        {
          label: <FormattedMessage id="Merchant aliases" />,
          to: '/merchant-aliases',
          icon: StoreIcon,
          restricted: MERCHANT,
        },
        {
          label: <FormattedMessage id="Settings" />,
          to: '/settings',
          icon: SettingsIcon,
          restricted: ADMIN,
        },
        {
          label: <FormattedMessage id="Refunds" />,
          to: '/refunds',
          icon: MoneyOffIcon,
          restricted: ADMIN,
        },
        {
          label: <FormattedMessage id="IPs blacklist" />,
          to: '/ips-blacklist',
          icon: ListAltIcon,
          restricted: ADMIN,
        },
        {
          label: <FormattedMessage id="Routines" />,
          to: '/routines',
          icon: AvTimerIcon,
          restricted: ADMIN,
        },
        {
          label: <FormattedMessage id="Email templates" />,
          to: '/email-templates',
          icon: EmailIcon,
          restricted: ADMIN,
        },
      ],
    },
  ];

  const navItemsFiltered = navItems
    .filter((nav) =>
      nav.restricted
        ? hasRights(role, nav.restricted, nav.disableForReadonly, isReadonly)
        : true,
    )
    .map((navList) => ({
      ...navList,
      content: navList.content.filter((nav) =>
        nav.restricted
          ? hasRights(role, nav.restricted, nav.disableForReadonly, isReadonly)
          : true,
      ),
    }));

  return (
    <>
      <SidebarMenu component="div" pages={navItemsFiltered} title="" />
    </>
  );
};

export default NavItems;
