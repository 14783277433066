import endOfMonth from 'date-fns/esm/endOfMonth';
import startOfMonth from 'date-fns/esm/startOfMonth';

export const SET_CUSTOMERS_FILTER_SEARCH =
  'FILTERS/SET_CUSTOMERS_FILTER_SEARCH';
export const SET_CUSTOMERS_FILTER_DEBT_STATUS =
  'FILTERS/SET_CUSTOMERS_FILTER_DEBT_STATUS';

export const SET_INVOICES_FILTER_DATE_FROM =
  'FILTERS/SET_INVOICES_FILTER_DATE_FROM';
export const SET_INVOICES_FILTER_DATE_TO =
  'FILTERS/SET_INVOICES_FILTER_DATE_TO';
// export const SET_INVOICES_FILTER_SELECTED_MERCHANT =
//   'FILTERS/SET_INVOICES_FILTER_SELECTED_MERCHANT';
export const SET_INVOICES_FILTER_SETTLEMENT =
  'FILTERS/SET_INVOICES_FILTER_SETTLEMENT';
export const SET_INVOICES_FILTER_PAYMENT_STATUS =
  'FILTERS/SET_INVOICES_FILTER_PAYMENT_STATUS';
export const SET_INVOICES_FILTER_OVERDUE =
  'FILTERS/SET_INVOICES_FILTER_OVERDUE';
export const SET_INVOICES_FILTER_SEARCH = 'FILTERS/SET_INVOICES_FILTER_SEARCH';
export const SET_INVOICES_FILTER_AMOUNT_SEARCH =
  'FILTERS/SET_INVOICES_FILTER_AMOUNT_SEARCH';
export const SET_INVOICES_FILTER_CANCEL_STATUS =
  'FILTERS/SET_INVOICES_FILTER_CANCEL_STATUS';
export const SET_INVOICES_FILTER_MERCHANT =
  'FILTERS/SET_INVOICES_FILTER_MERCHANT';

export const SET_SESSIONS_FILTER_DATE_FROM =
  'FILTERS/SET_SESSIONS_FILTER_DATE_FROM';
export const SET_SESSIONS_FILTER_DATE_TO =
  'FILTERS/SET_SESSIONS_FILTER_DATE_TO';
export const SET_SESSIONS_FILTER_STATUS = 'FILTERS/SET_SESSIONS_FILTER_STATUS';
export const SET_SESSIONS_FILTER_SEARCH = 'FILTERS/SET_SESSIONS_FILTER_SEARCH';
export const SET_SESSIONS_FILTER_MERCHANT =
  'FILTERS/SET_SESSIONS_FILTER_MERCHANT';
export const SET_SESSIONS_FILTER_TYPE =
  'FILTERS/SET_SESSIONS_FILTER_TYPE';

export const SET_SETTLEMENTS_FILTER_DATE_FROM =
  'FILTERS/SET_SETTLEMENTS_FILTER_DATE_FROM';
export const SET_SETTLEMENTS_FILTER_DATE_TO =
  'FILTERS/SET_SETTLEMENTS_FILTER_DATE_TO';
export const SET_SETTLEMENTS_FILTER_MERCHANT =
  'FILTERS/SET_SETTLEMENTS_FILTER_MERCHANT';
export const SET_SETTLEMENTS_FILTER_STATUS =
  'FILTERS/SET_SETTLEMENTS_FILTER_STATUS';

export const SET_DASHBOARD_FILTER_DATE_FROM =
  'FILTERS/SET_DASHBOARD_FILTER_DATE_FROM';
export const SET_DASHBOARD_FILTER_DATE_TO =
  'FILTERS/SET_DASHBOARD_FILTER_DATE_TO';
export const SET_DASHBOARD_FILTER_MERCHANT =
  'FILTERS/SET_DASHBOARD_FILTER_MERCHANT';

export const SET_MERCHANTS_FILTER_SEARCH =
  'FILTERS/SET_MERCHANTS_FILTER_SEARCH';

export const SET_ORDERS_FILTER_DATE_FROM =
  'FILTERS/SET_ORDERS_FILTER_DATE_FROM';
export const SET_ORDERS_FILTER_DATE_TO = 'FILTERS/SET_ORDERS_FILTER_DATE_TO';
export const SET_ORDERS_FILTER_SORT_BY = 'FILTERS/SET_ORDERS_FILTER_SORT_BY';
export const SET_ORDERS_FILTER_MERCHANT = 'FILTERS/SET_ORDERS_FILTER_MERCHANT';
export const SET_ORDERS_FILTER_CANCEL_STATUS = 'FILTERS/SET_ORDERS_FILTER_CANCEL_STATUS';
export const SET_ORDERS_FILTER_PAYMENT_METHOD = 'FILTERS/SET_ORDERS_FILTER_PAYMENT_METHOD';
export const SET_ORDERS_FILTER_PAYMENT_STATUS = 'FILTERS/SET_ORDERS_FILTER_PAYMENT_STATUS';
export const SET_ORDERS_FILTER_OVERDUE = 'FILTERS/SET_ORDERS_FILTER_OVERDUE';

export const SET_PREREGISTRATIONS_FILTER_DATE_FROM =
  'FILTERS/SET_PREREGISTRATIONS_FILTER_DATE_FROM';
export const SET_PREREGISTRATIONS_FILTER_DATE_TO =
  'FILTERS/SET_PREREGISTRATIONS_FILTER_DATE_TO';
export const SET_PREREGISTRATIONS_FILTER_STATUS =
  'FILTERS/SET_PREREGISTRATIONS_FILTER_STATUS';
export const SET_PREREGISTRATIONS_FILTER_TYPES =
  'FILTERS/SET_PREREGISTRATIONS_FILTER_TYPES';
export const SET_PREREGISTRATIONS_FILTER_SEARCH =
  'FILTERS/SET_PREREGISTRATIONS_FILTER_SEARCH';

export const SET_MERCHANT_ALIASES_FILTER_MERCHANT =
  'FILTERS/SET_MERCHANT_ALIASES_FILTER_MERCHANT';

export const SET_REFUNDS_FILTER_DATE_FROM = 'FILTERS/SET_REFUNDS_FILTER_DATE_FROM';
export const SET_REFUNDS_FILTER_DATE_TO = 'FILTERS/SET_REFUNDS_FILTER_DATE_TO';
export const SET_REFUNDS_FILTER_STATUS = 'FILTERS/SET_REFUNDS_FILTER_STATUS';
export const SET_REFUNDS_FILTER_MERCHANT = 'FILTERS/SET_REFUNDS_FILTER_MERCHANT';
export const SET_REFUNDS_FILTER_SEARCH = 'FILTERS/SET_REFUNDS_FILTER_SEARCH';

export const setCustomersFilterSearch = (payload) => ({
  type: SET_CUSTOMERS_FILTER_SEARCH,
  payload,
});
export const setCustomersFilterDebtStatus = (payload) => ({
  type: SET_CUSTOMERS_FILTER_DEBT_STATUS,
  payload,
});

export const setInvoicesFilterDateFrom = (payload) => ({
  type: SET_INVOICES_FILTER_DATE_FROM,
  payload,
});
export const setInvoicesFilterDateTo = (payload) => ({
  type: SET_INVOICES_FILTER_DATE_TO,
  payload,
});
// export const setInvoicesFilterSelectedMerchant = (payload) => ({
//   type: SET_INVOICES_FILTER_SELECTED_MERCHANT,
//   payload,
// });
export const setInvoicesFilterSettlement = (payload) => ({
  type: SET_INVOICES_FILTER_SETTLEMENT,
  payload,
});
export const setInvoicesFilterPaymentStatus = (payload) => ({
  type: SET_INVOICES_FILTER_PAYMENT_STATUS,
  payload,
});
export const setInvoicesFilterOverdue = (payload) => ({
  type: SET_INVOICES_FILTER_OVERDUE,
  payload,
});
export const setInvoicesFilterSearch = (payload) => ({
  type: SET_INVOICES_FILTER_SEARCH,
  payload,
});
export const setInvoicesFilterAmountSearch = (payload) => ({
  type: SET_INVOICES_FILTER_AMOUNT_SEARCH,
  payload,
});
export const setInvoicesFilterCancelStatus = (payload) => ({
  type: SET_INVOICES_FILTER_CANCEL_STATUS,
  payload,
});
export const setInvoicesFilterMerchant = (payload) => ({
  type: SET_INVOICES_FILTER_MERCHANT,
  payload,
});

export const setSessionsFilterDateFrom = (payload) => ({
  type: SET_SESSIONS_FILTER_DATE_FROM,
  payload,
});
export const setSessionsFilterDateTo = (payload) => ({
  type: SET_SESSIONS_FILTER_DATE_TO,
  payload,
});
export const setSessionsFilterStatus = (payload) => ({
  type: SET_SESSIONS_FILTER_STATUS,
  payload,
});
export const setSessionsFilterSearch = (payload) => ({
  type: SET_SESSIONS_FILTER_SEARCH,
  payload,
});
export const setSessionsFilterMerchant = (payload) => ({
  type: SET_SESSIONS_FILTER_MERCHANT,
  payload,
});
export const setSessionsFilterType = (payload) => ({
  type: SET_SESSIONS_FILTER_TYPE,
  payload,
});

export const setSettlementsFilterDateFrom = (payload) => ({
  type: SET_SETTLEMENTS_FILTER_DATE_FROM,
  payload,
});
export const setSettlementsFilterDateTo = (payload) => ({
  type: SET_SETTLEMENTS_FILTER_DATE_TO,
  payload,
});
export const setSettlementsFilterMerchant = (payload) => ({
  type: SET_SETTLEMENTS_FILTER_MERCHANT,
  payload,
});
export const setSettlementsFilterStatus = (payload) => ({
  type: SET_SETTLEMENTS_FILTER_STATUS,
  payload,
});

export const setDashboardFilterDateFrom = (payload) => ({
  type: SET_DASHBOARD_FILTER_DATE_FROM,
  payload,
});
export const setDashboardFilterDateTo = (payload) => ({
  type: SET_DASHBOARD_FILTER_DATE_TO,
  payload,
});
export const setDashboardFilterMerchant = (payload) => ({
  type: SET_DASHBOARD_FILTER_MERCHANT,
  payload,
});

export const setMerchantsFilterSearch = (payload) => ({
  type: SET_MERCHANTS_FILTER_SEARCH,
  payload,
});

export const setOrdersFilterDateFrom = (payload) => ({
  type: SET_ORDERS_FILTER_DATE_FROM,
  payload,
});
export const setOrdersFilterDateTo = (payload) => ({
  type: SET_ORDERS_FILTER_DATE_TO,
  payload,
});
export const setOrdersFilterSortBy = (payload) => ({
  type: SET_ORDERS_FILTER_SORT_BY,
  payload,
});
export const setOrdersFilterMerchant = (payload) => ({
  type: SET_ORDERS_FILTER_MERCHANT,
  payload,
});
export const setOrdersFilterCancelStatus = (payload) => ({
  type: SET_ORDERS_FILTER_CANCEL_STATUS,
  payload,
});
export const setOrdersFilterPaymentMethod = (payload) => ({
  type: SET_ORDERS_FILTER_PAYMENT_METHOD,
  payload,
});
export const setOrdersFilterPaymentStatus = (payload) => ({
  type: SET_ORDERS_FILTER_PAYMENT_STATUS,
  payload,
});
export const setOrdersFilterOverdue = (payload) => ({
  type: SET_ORDERS_FILTER_OVERDUE,
  payload,
});

export const setPreregistrationsFilterDateFrom = (payload) => ({
  type: SET_PREREGISTRATIONS_FILTER_DATE_FROM,
  payload,
});
export const setPreregistrationsFilterDateTo = (payload) => ({
  type: SET_PREREGISTRATIONS_FILTER_DATE_TO,
  payload,
});
export const setPreregistrationsFilterStatus = (payload) => ({
  type: SET_PREREGISTRATIONS_FILTER_STATUS,
  payload,
});
export const setPreregistrationsFilterTypes = (payload) => ({
  type: SET_PREREGISTRATIONS_FILTER_TYPES,
  payload,
});
export const setPreregistrationsFilterSearch = (payload) => ({
  type: SET_PREREGISTRATIONS_FILTER_SEARCH,
  payload,
});

export const setMerchantAliasesFilterMerchant = (payload) => ({
  type: SET_MERCHANT_ALIASES_FILTER_MERCHANT,
  payload,
});

export const setRefundsFilterDateFrom = (payload) => ({
  type: SET_REFUNDS_FILTER_DATE_FROM,
  payload,
});
export const setRefundsFilterDateTo = (payload) => ({
  type: SET_REFUNDS_FILTER_DATE_TO,
  payload,
});
export const setRefundsFilterStatus = (payload) => ({
  type: SET_REFUNDS_FILTER_STATUS,
  payload,
});
export const setRefundsFilterMerchant = (payload) => ({
  type: SET_REFUNDS_FILTER_MERCHANT,
  payload,
});
export const setRefundsFilterSearch = (payload) => ({
  type: SET_REFUNDS_FILTER_SEARCH,
  payload,
});

const initialState = {
  customersFiltersSearch: '',
  customersFiltersDebtStatus: 'ALL',

  invoicesFiltersDateFrom: startOfMonth(new Date()),
  invoicesFiltersDateTo: endOfMonth(new Date()),
  invoicesFiltersSettlement: 'ALL',
  invoicesFiltersOverdue: 'ALL',
  invoicesFiltersSearch: '',
  invoicesFiltersAmountSearch: '',
  invoicesFiltersCancelStatus: 'ALL',
  invoicesFiltersMerchant: null,
  invoicesFiltersPaymentStatus: [],

  sessionsFiltersDateFrom: startOfMonth(new Date()),
  sessionsFiltersDateTo: endOfMonth(new Date()),
  sessionsFiltersStatus: [],
  sessionsFiltersSearch: '',
  sessionsFiltersMerchant: null,
  sessionsFiltersType: 'ALL',

  settlementsFiltersDateFrom: startOfMonth(new Date()),
  settlementsFiltersDateTo: endOfMonth(new Date()),
  settlementsFiltersMerchant: null,
  settlementsFiltersStatus: null,

  dashboardFiltersDateFrom: startOfMonth(new Date()),
  dashboardFiltersDateTo: endOfMonth(new Date()),
  dashboardFiltersMerchant: null,

  merchantsFiltersSearch: '',

  ordersFiltersDateFrom: null,
  ordersFiltersDateTo: endOfMonth(new Date()),
  ordersFiltersSortBy: 'status',
  ordersFiltersMerchant: null,
  ordersFiltersCancelStatus: 'ALL',
  ordersFiltersPaymentMethod: [],
  ordersFiltersPaymentStatus: [],
  ordersFiltersOverdue: 'ALL',

  preregistrationsFiltersDateFrom: startOfMonth(new Date()),
  preregistrationsFiltersDateTo: endOfMonth(new Date()),
  preregistrationsFiltersStatus: [],
  preregistrationsFiltersTypes: [],
  preregistrationsFiltersSearch: '',

  merchantAliasesFiltersMerchant: null,

  refundsFiltersDateFrom: startOfMonth(new Date()),
  refundsFiltersDateTo: endOfMonth(new Date()),
  refundsFiltersStatus: 'ALL',
  refundsFiltersMerchant: null,
  refundsFiltersSearch: '',
};

/**
 * @param state
 * @param action
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMERS_FILTER_SEARCH:
      return {
        ...state,
        customersFiltersSearch: action.payload,
      };

    case SET_CUSTOMERS_FILTER_DEBT_STATUS:
      return {
        ...state,
        customersFiltersDebtStatus: action.payload,
      };

    case SET_INVOICES_FILTER_DATE_FROM:
      return {
        ...state,
        invoicesFiltersDateFrom: action.payload,
      };
    case SET_INVOICES_FILTER_DATE_TO:
      return {
        ...state,
        invoicesFiltersDateTo: action.payload,
      };
    // case SET_INVOICES_FILTER_SELECTED_MERCHANT:
    //   return {
    //     ...state,
    //     invoicesFiltersSelectedMerchant: action.payload,
    //   };
    case SET_INVOICES_FILTER_SETTLEMENT:
      return {
        ...state,
        invoicesFiltersSettlement: action.payload,
      };
    case SET_INVOICES_FILTER_PAYMENT_STATUS:
      return {
        ...state,
        invoicesFiltersPaymentStatus: action.payload,
      };
    case SET_INVOICES_FILTER_OVERDUE:
      return {
        ...state,
        invoicesFiltersOverdue: action.payload,
      };
    case SET_INVOICES_FILTER_SEARCH:
      return {
        ...state,
        invoicesFiltersSearch: action.payload,
      };
    case SET_INVOICES_FILTER_AMOUNT_SEARCH:
      return {
        ...state,
        invoicesFiltersAmountSearch: action.payload,
      };
    case SET_INVOICES_FILTER_CANCEL_STATUS:
      return {
        ...state,
        invoicesFiltersCancelStatus: action.payload,
      };
    case SET_INVOICES_FILTER_MERCHANT:
      return {
        ...state,
        invoicesFiltersMerchant: action.payload,
      };

    case SET_SESSIONS_FILTER_DATE_FROM:
      return {
        ...state,
        sessionsFiltersDateFrom: action.payload,
      };
    case SET_SESSIONS_FILTER_DATE_TO:
      return {
        ...state,
        sessionsFiltersDateTo: action.payload,
      };
    case SET_SESSIONS_FILTER_STATUS:
      return {
        ...state,
        sessionsFiltersStatus: action.payload,
      };
    case SET_SESSIONS_FILTER_SEARCH:
      return {
        ...state,
        sessionsFiltersSearch: action.payload,
      };
    case SET_SESSIONS_FILTER_MERCHANT:
      return {
        ...state,
        sessionsFiltersMerchant: action.payload,
      };
    case SET_SESSIONS_FILTER_TYPE:
      return {
        ...state,
        sessionsFiltersType: action.payload,
      };

    case SET_SETTLEMENTS_FILTER_DATE_FROM:
      return {
        ...state,
        settlementsFiltersDateFrom: action.payload,
      };
    case SET_SETTLEMENTS_FILTER_DATE_TO:
      return {
        ...state,
        settlementsFiltersDateTo: action.payload,
      };
    case SET_SETTLEMENTS_FILTER_MERCHANT:
      return {
        ...state,
        settlementsFiltersMerchant: action.payload,
      };
    case SET_SETTLEMENTS_FILTER_STATUS:
      return {
        ...state,
        settlementsFiltersStatus: action.payload,
      };

    case SET_DASHBOARD_FILTER_DATE_FROM:
      return {
        ...state,
        dashboardFiltersDateFrom: action.payload,
      };
    case SET_DASHBOARD_FILTER_DATE_TO:
      return {
        ...state,
        dashboardFiltersDateTo: action.payload,
      };
    case SET_DASHBOARD_FILTER_MERCHANT:
      return {
        ...state,
        dashboardFiltersMerchant: action.payload,
      };
    case SET_MERCHANTS_FILTER_SEARCH:
      return {
        ...state,
        merchantsFiltersSearch: action.payload,
      };

    case SET_ORDERS_FILTER_DATE_FROM:
      return {
        ...state,
        ordersFiltersDateFrom: action.payload,
      };
    case SET_ORDERS_FILTER_DATE_TO:
      return {
        ...state,
        ordersFiltersDateTo: action.payload,
      };
    case SET_ORDERS_FILTER_SORT_BY:
      return {
        ...state,
        ordersFiltersSortBy: action.payload,
      };
    case SET_ORDERS_FILTER_MERCHANT:
      return {
        ...state,
        ordersFiltersMerchant: action.payload,
      };
    case SET_ORDERS_FILTER_CANCEL_STATUS:
      return {
        ...state,
        ordersFiltersCancelStatus: action.payload,
      };
    case SET_ORDERS_FILTER_PAYMENT_METHOD:
      return {
        ...state,
        ordersFiltersPaymentMethod: action.payload,
      };
    case SET_ORDERS_FILTER_PAYMENT_STATUS:
      return {
        ...state,
        ordersFiltersPaymentStatus: action.payload,
      };
    case SET_ORDERS_FILTER_OVERDUE:
      return {
        ...state,
        ordersFiltersOverdue: action.payload,
      };

    case SET_PREREGISTRATIONS_FILTER_DATE_FROM:
      return {
        ...state,
        preregistrationsFiltersDateFrom: action.payload,
      };
    case SET_PREREGISTRATIONS_FILTER_DATE_TO:
      return {
        ...state,
        preregistrationsFiltersDateTo: action.payload,
      };
    case SET_PREREGISTRATIONS_FILTER_STATUS:
      return {
        ...state,
        preregistrationsFiltersStatus: action.payload,
      };
    case SET_PREREGISTRATIONS_FILTER_TYPES:
      return {
        ...state,
        preregistrationsFiltersTypes: action.payload,
      };
    case SET_PREREGISTRATIONS_FILTER_SEARCH:
      return {
        ...state,
        preregistrationsFiltersSearch: action.payload,
      };

    case SET_MERCHANT_ALIASES_FILTER_MERCHANT:
      return {
        ...state,
        merchantAliasesFiltersMerchant: action.payload,
      };

    case SET_REFUNDS_FILTER_DATE_FROM:
      return {
        ...state,
        refundsFiltersDateFrom: action.payload,
      };
    case SET_REFUNDS_FILTER_DATE_TO:
      return {
        ...state,
        refundsFiltersDateTo: action.payload,
      };
    case SET_REFUNDS_FILTER_STATUS:
      return {
        ...state,
        refundsFiltersStatus: action.payload,
      };
    case SET_REFUNDS_FILTER_MERCHANT:
      return {
        ...state,
        refundsFiltersMerchant: action.payload,
      };
    case SET_REFUNDS_FILTER_SEARCH:
      return {
        ...state,
        refundsFiltersSearch: action.payload,
      };

    default:
      break;
  }
  return state;
}
