export const CreditDecisionModels = [
  {
    name: 'no rules',
    value: 0,
  },
  {
    name: 'v1',
    value: 1,
  },
  {
    name: 'v2',
    value: 2,
  },
  {
    name: 'v3',
    value: 3,
  },
  {
    name: 'v4',
    value: 4,
  },
];
