import React from 'react';
import { FormattedMessage } from 'react-intl';

import CustomBadge from '../../Common/CustomBadge';

/**
 * @param props
 * @param props.status
 */
export default function StatusFormat({ status = '' }) {
  if (!status) {
    return '';
  }

  let type = '';
  switch (status) {
    case 'CONFIRMED':
    case 'SIGNUP_COMPLETE':
    case 'RENEW_BANK_ID_COMPLETE':
      type = 'success';
      break;
    case 'ERROR':
      type = 'danger';
      break;
    case 'DENIED':
    case 'ABORTED':
      type = 'danger-secondary';
      break;
    case 'EXPIRED':
      type = 'warning';
      break;
    default:
      type = 'info';
  }
  return <CustomBadge type={type}><FormattedMessage id={status} /></CustomBadge>;
}
