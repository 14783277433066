import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { AnimatePresence, motion } from 'framer-motion';
import {
  lazy, Suspense, useEffect, useState,
} from 'react';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import Cookies from 'js-cookie';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

// Layout Blueprints
import { LeftSidebar, MinimalLayout } from './layout-blueprints';
import CustomerLayout from './customer-layout';
import { useAuthContext } from './providers/AuthProvider';
import MuiTheme from './theme';
import CreateNewSession from './layout-components/CreateNewSession';

import translations from './translations.json';
import { setLanguage } from './reducers/ThemeOptions';

const ErrorPage = lazy(() => import('./pages/ErrorPage'));

const Login = lazy(() => import('./pages/Login'));
const CustomerLogin = lazy(() => import('./pages/CustomerLogin'));
const ExpressPaymentLogin = lazy(() => import('./pages/ExpressPaymentLogin'));

const Dashboard = lazy(() => import('./pages/Dashboard'));

const Customers = lazy(() => import('./pages/Customers'));

const Merchants = lazy(() => import('./pages/Merchants'));
const Aliases = lazy(() => import('./pages/MerchantAliases'));

const Customer = lazy(() => import('./pages/Customer'));

const CustomerKYC = lazy(() => import('./pages/CustomerKYC'));

const Invoices = lazy(() => import('./pages/Invoices'));
const Invoice = lazy(() => import('./pages/Invoice'));

const Refunds = lazy(() => import('./pages/Refunds'));
const Refund = lazy(() => import('./pages/Refund'));

const Sessions = lazy(() => import('./pages/Sessions'));
const Session = lazy(() => import('./pages/Session'));

const Orders = lazy(() => import('./pages/Orders'));
const OrderDetails = lazy(() => import('./pages/Order'));

const Settlements = lazy(() => import('./pages/Settlements'));
const Settlement = lazy(() => import('./pages/Settlement'));
const SettlementReports = lazy(() => import('./pages/SettlementReports'));

const Settings = lazy(() => import('./pages/Settings'));

const CustomerHomePage = lazy(() => import('./customer-pages/home'));
const CustomerMyOrders = lazy(() => import('./customer-pages/my-orders'));
const CustomerMyOrder = lazy(() => import('./customer-pages/my-order'));
const CustomerMyProfile = lazy(() => import('./customer-pages/my-profile'));
const CustomerSupport = lazy(() => import('./customer-pages/support'));
const CustomerSuccessPayment = lazy(() => import('./customer-pages/success-payment'));
const CustomerErrorPayment = lazy(() => import('./customer-pages/error-payment'));

const ExpressPayment = lazy(() => import('./customer-pages/express-payment'));

const Preregistrations = lazy(() => import('./pages/Preregistrations'));
const Preregistration = lazy(() => import('./pages/Preregistration'));

const IpsBlacklist = lazy(() => import('./pages/IpsBlacklist'));

const Routines = lazy(() => import('./pages/Routines'));
const EmailTemplates = lazy(() => import('./pages/EmailTemplates'));

const Routes = ({ language, setLanguage }) => {
  const location = useLocation();
  const authData = useAuthContext();
  const isUserLogged = !!authData.token;
  const { role } = useAuthContext();
  const isUserHasAdminRights = isUserLogged && role === 'ADMIN';
  const isUserHasMerchantRights =
    isUserLogged && (role === 'MERCHANT' || role === 'ADMIN');
  const isUserHasCustomerRights =
    isUserLogged &&
    (role === 'CUSTOMER' || role === 'MERCHANT' || role === 'ADMIN' || !role);
  const isAdminViewingCustomer =
    role === 'ADMIN' && location.pathname.includes('customer-dashboard');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    if (Cookies.get('language')) {
      setLanguage(Cookies.get('language'));
    } else {
      Cookies.set('language', 'es');
      setLanguage('es');
    }
  }, [authData.token]);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
    },
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.01,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  };

  const CustomerRoute = ({ component: Component, ...options }) => (
    <Route
      {...options}
      render={(props) =>
        {
          const pathname = props.location.pathname.replace('/', '#');

          return isUserHasCustomerRights ? (
            <motion.div
              animate="in"
              exit="out"
              initial="initial"
              transition={pageTransition}
              variants={pageVariants}
            >
              <Component {...props} />
            </motion.div>
          ) : (
            <Redirect to={`/login${pathname === '#' ? '' : pathname}`} />
          )
        }
      }
    />
  );

  const MerchantRoute = ({ component: Component, ...options }) => (
    <Route
      {...options}
      render={(props) =>
        {
          const pathname = props.location.pathname.replace('/', '#');

          return isUserHasMerchantRights ? (
            <motion.div
              animate="in"
              exit="out"
              initial="initial"
              transition={pageTransition}
              variants={pageVariants}
            >
              <Component {...props} />
            </motion.div>
          ) : (
            <Redirect to={`/login${pathname === '#' ? '' : pathname}`} />
          )
        }
      }
    />
  );

  const AdminRoute = ({ component: Component, ...options }) => (
    <Route
      {...options}
      render={(props) =>
        {
          const pathname = props.location.pathname.replace('/', '#');

          return isUserHasAdminRights ? (
            <motion.div
              animate="in"
              exit="out"
              initial="initial"
              transition={pageTransition}
              variants={pageVariants}
            >
              <Component {...props} />
            </motion.div>
          ) : (
            <Redirect to={`/login${pathname === '#' ? '' : pathname}`} />
          )
        }
      }
    />
  );

  const SuspenseLoading = () => (
    <>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader loading color="#5383ff" />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Please wait a little while the application is loading
        </div>
      </div>
    </>
  );

  const Layout = isUserLogged ? LeftSidebar : MinimalLayout;

  let isCustomerDashboard = false;
  let isCustomerDashboardSuccessPage = false;
  let isCustomerDashboardErrorPage = false;
  let isDashboard = false;
  let isExpressPayment = false;

  if (!loading) {
    if (location.pathname.includes('success-payment')) {
      isCustomerDashboardSuccessPage = true;
    } else if (location.pathname.includes('error-payment')) {
      isCustomerDashboardErrorPage = true;
    } else if (role === 'CUSTOMER' || isAdminViewingCustomer) {
      isCustomerDashboard = true;
    } else if (role === 'EXPRESS_PAYMENT') {
      isExpressPayment = true;
    } else {
      isDashboard = true;
    }
  }

  //TODO: hide user menu, when clicked somewhere else

  return (
    <IntlProvider
      defaultLocale={language}
      locale={language}
      messages={translations[language]}
    >
      <ThemeProvider theme={MuiTheme}>
        <AnimatePresence>
          <Suspense fallback={<SuspenseLoading />}>
            {loading && (
              <SuspenseLoading />
            )}
            {isCustomerDashboard && (
              <CustomerLayout>
                <Switch key={location.pathname} location={location}>
                  {isUserLogged && <Redirect exact from="/login" to="/" />}

                  <CustomerRoute exact component={CustomerHomePage} path="/" />
                  <CustomerRoute
                    exact
                    component={CustomerHomePage}
                    path="/customer-dashboard"
                  />
                  <CustomerRoute
                    exact
                    component={CustomerMyOrders}
                    path="/my-orders"
                  />
                  <CustomerRoute
                    exact
                    component={CustomerMyOrders}
                    path="/customer-dashboard/my-orders"
                  />
                  <CustomerRoute
                    exact
                    component={CustomerMyOrder}
                    path="/my-orders/:id"
                  />
                  <CustomerRoute
                    exact
                    component={CustomerMyOrder}
                    path="/customer-dashboard/my-orders/:id"
                  />
                  <CustomerRoute
                    exact
                    component={CustomerMyProfile}
                    path="/my-profile"
                  />
                  <CustomerRoute
                    exact
                    component={CustomerMyProfile}
                    path="/my-profile/edit"
                  />
                  <CustomerRoute
                    exact
                    component={CustomerSupport}
                    path="/support"
                  />

                  <CustomerRoute
                    component={ErrorPage}
                    path="/404" />
                  <CustomerRoute
                    component={ErrorPage}
                    path="/500" />

                  <CustomerRoute component={ErrorPage} />
                </Switch>
              </CustomerLayout>
            )}
            {isExpressPayment && (
              <ExpressPayment />
            )}
            {isCustomerDashboardSuccessPage && (
              <CustomerSuccessPayment />
            )}
            {isCustomerDashboardErrorPage && (
              <CustomerErrorPayment />
            )}
            {isDashboard && (
              <Layout>
                <CreateNewSession />
                <Switch key={location.pathname} location={location}>
                  {isUserLogged &&
                    ((isUserHasAdminRights && (
                      <Redirect exact from="/login" to="/dashboard" />
                    )) ||
                      (isUserHasMerchantRights && (
                        <Redirect exact from="/login" to="/orders" />
                      )) || <Redirect exact from="/login" to="/invoices" />)}

                  {isUserLogged &&
                    ((isUserHasAdminRights && (
                      <Redirect exact from="/" to="/dashboard" />
                    )) ||
                      (isUserHasMerchantRights && (
                        <Redirect exact from="/" to="/orders" />
                      )))}

                  <Route
                    component={
                      document.location.href.includes('my.')
                        ? CustomerLogin
                        : document.location.href.includes('express-payment.')
                          ? ExpressPaymentLogin
                          : Login
                    }
                    path="/login"
                  />
                  <Route component={CustomerLogin} path="/customer-login" />
                  <Route component={Login} path="/merchant-login" />
                  <AdminRoute exact component={Dashboard} path="/dashboard" />
                  <MerchantRoute
                    exact
                    component={Customers}
                    path="/customers"
                  />
                  <AdminRoute exact component={Refunds} path="/refunds" />
                  <AdminRoute
                    exact
                    component={Refund}
                    path="/refund/:id"
                  />
                  <AdminRoute exact component={Merchants} path="/merchants" />
                  <MerchantRoute
                    exact
                    component={Customer}
                    path="/customers/:id"
                  />
                  <AdminRoute
                    exact
                    component={CustomerKYC}
                    path="/customers/:id/kyc"
                  />
                  <AdminRoute
                    exact
                    component={Settings}
                    path="/settings"
                  />
                  <CustomerRoute exact component={Invoices} path="/invoices" />
                  <CustomerRoute
                    exact
                    component={Invoice}
                    path="/invoices/:id"
                  />
                  <CustomerRoute exact component={Sessions} path="/sessions" />
                  <MerchantRoute
                    exact
                    component={Session}
                    path="/sessions/:id"
                  />
                  <MerchantRoute exact component={Orders} path="/orders" />
                  <MerchantRoute
                    exact
                    component={OrderDetails}
                    path="/orders/:id"
                  />
                  <MerchantRoute
                    exact
                    component={Settlements}
                    path="/settlements"
                  />
                  <MerchantRoute
                    exact
                    component={Settlement}
                    path="/settlements/:id"
                  />
                  <AdminRoute
                    exact
                    component={SettlementReports}
                    path="/settlement-reports"
                  />
                  <CustomerRoute exact component={Preregistrations} path="/preregistrations" />
                  <MerchantRoute
                    exact
                    component={Preregistration}
                    path="/preregistrations/:id"
                      />
                  <MerchantRoute
                    exact
                    component={Aliases}
                    path="/merchant-aliases"
                  />
                  <AdminRoute
                    exact
                    component={IpsBlacklist}
                    path="/ips-blacklist"
                  />
                  <AdminRoute
                    exact
                    component={Routines}
                    path="/routines"
                  />
                  <AdminRoute
                    exact
                    component={EmailTemplates}
                    path="/email-templates"
                  />
                  <MerchantRoute
                    component={ErrorPage}
                    path="/400" />
                  <MerchantRoute
                    component={ErrorPage}
                    path="/403" />
                  <MerchantRoute
                    component={ErrorPage}
                    path="/404" />
                  <MerchantRoute
                    component={ErrorPage}
                    path="/500" />

                  <MerchantRoute component={ErrorPage} />
                </Switch>
              </Layout>
            )}
          </Suspense>
        </AnimatePresence>
      </ThemeProvider>
    </IntlProvider>
  );
};

const mapStateToProps = (state) => ({
  language: state.ThemeOptions.language,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (payload) => dispatch(setLanguage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
