import React, { useState } from 'react';

import { Card, Button, CircularProgress } from '@material-ui/core';
import { Grid, MenuItem, TextField } from '@material-ui/core/esm';
import { DatePicker } from '@material-ui/pickers';
import { Form, Field, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';

import { feeSettingSchema } from '../../utils/validationSchemas';
import { PAYMENT_METHODS } from '../../constants/paymentMethods';
import { createFeeSettings } from '../../providers/api';

function FeeSetting({
  feeSetting,
  setRefreshFees,
}) {
  const [currentFeeSettings] = useState(feeSetting);
  const [isLoading, setIsLoading] = useState(false);

  const handlerCreateFeeSettings = async (value) => {
    try {
      setIsLoading(true);

      const responseData = {
        activatedAt: value.activatedAt.toISOString().substring(0, 10),
        expiredAt: value.expiredAt ? value.expiredAt.toISOString().substring(0, 10) : null,
        consumerFee: Number(value.consumerFee),
        merchantDiscount: value.merchantDiscount ? Number(value.merchantDiscount) : null,
        feeId: value.feeId,
        paymentMethod: value.paymentMethod,
        merchantId: value.merchantId,
      }

      const { data } = await createFeeSettings(responseData);

      if (data) {
        setRefreshFees(true);
      }
    } catch (error) {
      console.log('Error save fee settings: ', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Formik
      initialValues={currentFeeSettings}
      validationSchema={feeSettingSchema}
      onSubmit={handlerCreateFeeSettings}
    >
      {({ errors, setFieldValue }) => (
        <Form>
          <Card key={feeSetting.feeId} className="mb-3" >
            <Grid container className="card-box p-3 pb-0" style={{ backgroundColor: 'rgba(25,25,25, 0.03)' }}>
              <Grid container spacing={2} >
                <Field name="activatedAt">
                  {({ field }) => (
                    <Grid item sm={6} xs={12}>
                      <DatePicker
                        fullWidth
                        animateYearScrolling
                        autoOk
                        format="yyyy-MM-dd"
                        inputVariant="outlined"
                        label={<FormattedMessage id='Activated at' />}
                        variant="inline"
                        error={errors.activatedAt}
                        {...field}
                        onChange={date => setFieldValue('activatedAt', date)}
                      />
                    </Grid>
                  )}
                </Field>

                <Field name="expiredAt">
                  {({ field }) => (
                    <Grid item sm={6} xs={12}>
                      <DatePicker
                        fullWidth
                        animateYearScrolling
                        autoOk
                        format="yyyy-MM-dd"
                        inputVariant="outlined"
                        label={<FormattedMessage id='Expired at' />}
                        variant="inline"
                        {...field}
                        onChange={date => setFieldValue('expiredAt', date)}
                      />
                    </Grid>
                  )}
                </Field>

                <Field name="paymentMethod">
                  {({ field }) => (
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        select
                        label={<><FormattedMessage id="Payment Method" /> *</>}
                        error={errors.paymentMethod}
                        {...field}
                        SelectProps={{
                          renderValue: (value) => <FormattedMessage id={value} />
                        }}
                        variant="outlined"
                      >
                        {PAYMENT_METHODS.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            <FormattedMessage id={item} />
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                </Field>

                <Field name="consumerFee">
                  {({ field }) => (
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        number
                        label={<><FormattedMessage id="Consumer fee from 0 to 100" /> *</>}
                        variant="outlined"
                        error={errors.consumerFee}
                        {...field}
                      />
                    </Grid>
                  )}
                </Field>

                <Field name="merchantDiscount">
                  {({ field }) => (
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        number
                        label={<FormattedMessage id="Merchant discount from 0 to 100" />}
                        variant="outlined"
                        error={errors.merchantDiscount}
                        {...field}
                      />
                    </Grid>
                  )}
                </Field>
              </Grid>

              <Grid container justify="flex-end" spacing={4} className={"mt-1"}>
                <Grid item>
                  {isLoading && (
                    <CircularProgress
                      className="circular-progress"
                      color="secondary"
                      size={18}
                    />
                  )}
                  <Button
                    color="primary"
                    variant="outlined"
                    type="submit"
                  >
                    <FormattedMessage id={"Save"} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default FeeSetting;
