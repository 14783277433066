import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext, Field, getIn } from 'formik';
import TextField from '@material-ui/core/esm/TextField';

import { useAuthContext } from '../../providers/AuthProvider';

import MainTable from '../MainTable';

const EMPTY_ARR = [];

export default function MerchantFeesSettings({ name }) {
  const { values } = useFormikContext();

  const formikSlice = getIn(values, name) || EMPTY_ARR;
  const [tableRows, setTableRows] = useState(formikSlice);

  useEffect(() => {
    setTableRows(formikSlice);
  }, [formikSlice]);

  const intl = useIntl();
  const { isReadonly } = useAuthContext();

  const columns = [
    {
      title: <FormattedMessage id="Payment Method" />,
      render: ({ paymentMethod }) => <FormattedMessage id={paymentMethod} />,
    },
    {
      title: <FormattedMessage id="Consumer fee" />,
      render: (row, rowIndex) => (
        <Field name={`${name}[${rowIndex}].merchantConsumerFee`}>
          {({ field }) => (
            <TextField
              fullWidth
              disabled={isReadonly || !values.paymentMethods.includes(row.paymentMethod)}
              InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
              label={`${intl.formatMessage({ id: 'Default' })} ${tableRows[rowIndex].globalConsumerFee}%`}
              type="number"
              variant="outlined"
              {...field}
            />
          )}
        </Field>
      ),
    },
    {
      title: <FormattedMessage id="Discount" />,
      render: (row, rowIndex) => (
        <Field name={`${name}[${rowIndex}].merchantDiscount`}>
          {({ field }) => (
            <TextField
              fullWidth
              disabled={isReadonly || !values.paymentMethods.includes(row.paymentMethod)}
              InputProps={{ inputProps: { min: 0, step: 0.1 } }}
              label={`${intl.formatMessage({ id: 'Default' })} ${tableRows[rowIndex].globalDiscount}%`}
              type="number"
              variant="outlined"
              {...field}
            />
          )}
        </Field>
      ),
    },
    {
      title: <FormattedMessage id="Enabled" />,
      render: (row) => (
        <Field
          disabled={isReadonly || values.defaultPaymentMethod === row.paymentMethod}
          name="paymentMethods"
          type="checkbox"
          value={row.paymentMethod}
        />
      ),
    },
    {
      title: <FormattedMessage id="Default" />,
      render: (row) => (
        <Field
          disabled={isReadonly || !values.paymentMethods.includes(row.paymentMethod)}
          name="defaultPaymentMethod"
          type="radio"
          value={row.paymentMethod}
        />
      ),
    },
  ];

  return (
    <div className="field">
      <MainTable columns={columns} data={tableRows} />
    </div>
  );
}
